<template>
    <div class="check-form-container">
        <Button type="button"
                class="saveButtonStyles p-mr-2"
                v-show="showWorksheet"
                @click="showCheckForm"
        >
            <img alt="logo" src="@/assets/img/customIcons/magic-wand.svg" style="width: 14px; height: 14px; margin-right: 6px" />
            <span class="p-button-label green">Проверить</span>
        </Button>
        <Dialog
            id="print"
            :visible.sync="showForm"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
        >
            <template #header>
                <div class="p-d-flex p-flex-column">
                    <h3 class="p-mb-2" style="min-width: 600px;">Ошибки. {{ formTitle }}</h3>
                    <div v-show="progress < 100">
                        <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 errorPos"
                             style="max-width: 1200px;"
                        >
                            Загрузка ошибок...
                        </div>
                    </div>
                </div>
            </template>
            <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0 errorPos"
                 style="max-width: 1200px; min-height: 400px"
            >
                <DataTable
                    v-if="errorList && errorList.length > 0"
                    :value="errorList"
                    stripedRows
                    class="p-datatable-striped role-list"
                    rowGroupMode="subheader"
                    groupRowsBy="worksheet.attributes.name"
                    responsiveLayout="scroll"
                    sortMode="single"
                    sortField="worksheet.attributes.name"
                    :sortOrder="1"
                    :scrollable="true"
                    :paginator="false"
                    :rows="200"
                    showGridlines
                    currentPageReportTemplate=""
                >
                    <template #header>
                    </template>
                    <Column field="worksheet.attributes.name" />
                    <Column field="formula">
                        <template #body="{ data }">
                            <div class="p-d-flex">
                                <div class="p-flex-column p-px-2" :style="data.explain ? 'width: 50%;' : ''">
                                    <span class="error-description green" v-html="data.cell"></span>
                                    <span>{{ data.message }}</span><br>
                                    <span class="error-description" v-html="data.formula"></span>
                                </div>
                                <div v-if="data.explain" class="p-flex-column p-px-2" style="width: 50%;">
                                    <span class="error-expain">{{ data.explain }}</span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <template #groupheader="slotProps">
                        <span class="worksheet-name">{{ slotProps.data.worksheet.attributes.name }}</span>
                    </template>
                    <template #paginatorLeft>
                        <div class="p-col-6"></div>
                    </template>
                </DataTable>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start p-py-4">
                    <Button v-show="errorList && errorList.length > 0" class="p-button" type="button" @click="printErrors">
                        <span class="p-button-label">Распечатать</span><span class="p-ink"></span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>

export default {
    name: 'checkFormDialog',

    props: {
        showWorksheet: {
            require: true,
            type: Boolean
        },

        formTitle: {
            require: true,
            type: String
        },

        errorList: {
            type: Array
        },

        progress: {
            type: Number
        }
    },

    emits: [ 'loadingChange', 'loadErrors' ],

    data() {
        return {
            showForm: false,
            cellData: null,
            cellIncluded: null,
            errorMeta: 0
        };
    },

    methods: {
        async loadErrors() {
            await this.$emit('loadErrors');
            this.showForm = true;
        },

        async showCheckForm() {
            this.$root.reachGoal('check');
            await this.loadErrors();
        },

        printErrors() {
            const prtHtml = document.getElementById('print').innerHTML;
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');

            WinPrint.document.write(`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .p-flex-column {
                flex-direction: column !important;
            }

            .p-d-flex {
                display: flex !important;
            }

            .p-dialog-footer {
                display: none;
            }

            .p-dialog-header-icons {
                display: none;
            }
            .customStyles {
                width: 100%;
                max-height: 100%;
                overflow: auto;
                z-index: 10;
            }

            .userContainer {
                align-items: start;
                background: #fff;
                padding: 1rem;
            }

            .greyBg {
                background: #f5f5f5;
            }

            .error-description {
                font-weight: 500;
                margin: 4px 0;
            }

            .p-card {
                box-shadow: none;
            }

            .p-card .p-card-body {
                border-radius: 0;
                padding: 0 !important;
                border: none;
            }

            .p-card .p-card-content {
                padding: 0 !important;
                font-size: 13px;
            }

            .cell-marker {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #495058;
                border-radius: 3px;
                cursor: pointer;
                display: inline-block !important;
                background: #FF8F8F;
                padding: 2px;
                margin: 1px;
            }

            .green .cell-marker {
                background: #C5E0C8;
            }

            .error-expain {
                color: #F44336;
            }

            .printHeader {
                display: none;
            }

            @media print {
                .printHeader {
                    display: block;
                }
            }
        </style>
    </head>
    <body>
        ${prtHtml}
    </body>
</html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },
    }
}
</script>

<style scoped>
.check-form-container {
    display: inline-flex;
}

.saveButtonStyles {
    padding: 10px;
}

::v-deep .p-datatable-thead {
    display: none;
}

::v-deep .p-rowgroup-header {
    background-color: #ccc !important;
    align-items: center;
    color: #272727 !important;
}

.worksheet-name {
    display: block;
    text-align: center;
}

.error-expain {
    color: #F44336;
}

.error-description {
    font-weight: 500;
    margin: 4px 0;
}

::v-deep .cell-marker {
    max-height: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px !important;
    color: #495058;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block !important;
    background: #FF8F8F;
    padding: 2px;
    margin: 1px;
}

::v-deep .green .cell-marker {
    background: #C5E0C8;
}

@media print {
    .p-button {
        display: none;
    }

    ::v-deep .p-datatable-scrollable-body {
        overflow-y: auto !important;
        height: 100%;
    }

    ::v-deep .p-datatable-striped .p-datatable-tbody > tr {
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }

    ::v-deep .p-datatable-striped .p-datatable-tbody {
        border-bottom: 1px solid #ccc;
    }

    ::v-deep .p-datatable-striped .p-datatable-tbody > tr > td {
        padding: 8px;
    }

    ::v-deep .p-dialog-header {
        color: black;
        font-size: 1rem;
        padding: 8px !important;
    }

    ::v-deep .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
        background-color: #eaeaea !important;
    }
}
</style>
